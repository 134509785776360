import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";
import { Input, TextArea } from "@progress/kendo-react-inputs";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getDeviceRegistrationStatus } from "../../DeviceEnrollment/Services/DeviceEnrollmentService";
import { Label } from "@progress/kendo-react-labels";
import { RFPROFILESETUP } from "../../../constants/applicationConstants";
import {
  DataTableHoc,
  Loader,
  ErrorFallback,
  GenerateNotification,
  buildNotification,
} from "smart-react";
import { GridColumnsList } from "../Components/Filter/FilterElements";
import DataColumns from "../Components/DataColumns/DataColumns";
import {
  ROW_LAYOUTS,
  PAGE_SIZE,
} from "../../../constants/applicationConstants";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../constants/eventDataTypes";
import {
  deleteFilter,
  applyFilter,
  saveFilters,
} from "../../../Utils/Filters/filterSliderHandler";
import ConfigurationContentSlider from "../Components/ContentSlider/ConfigurationContentSlider";
import { SliderCard } from "smart-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { extractNumberFromString } from "../../../Utils/Common/utils";
import "./RFProfileSetup.scss";
const BaseProfileSetupDataTable = React.lazy(
  () => import("../Components/DataTable/DataTable")
);
const ProfileSetupDataTable = DataTableHoc(BaseProfileSetupDataTable);

/**
 * RFPROFILESETUP main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The RFPROFILESETUP component.
 */
const RFProfileSetup = ({ profile }) => {
  const [userData, setUserData] = React.useState([]);
  const dataColumnsArray = DataColumns();
  // used to check API is ready to call
  const [isReady, setIsReady] = React.useState(false);
  const [editItem, setEditItem] = React.useState(null);
  const [devcod, setDevcod] = React.useState("");
  const [termID, setTermID] = React.useState("");
  const [selectedProfile, setSelectedProfile] = React.useState(null);
  const [showQRModal, setShowQRModal] = React.useState(false);
  const [qrData, setQRData] = React.useState("");
  const [dialogueLoading, setDialogueLoading] = React.useState(false);
  const [mtfTermType, setMtfTermType] = React.useState({
    value: "handheld",
    text: "Handheld",
  });
  const [mtfDisplayWidth, setMtfDisplayWidth] = React.useState(20);
  const [mtfDisplayHeight, setMtfDisplayHeight] = React.useState(16);
  const [localeId, setLocaleId] = React.useState({});
  const [localeData, setLocaleData] = React.useState([]);
  const [isQrCode, setIsQrCode] = React.useState(true);

  /**
   * Function to handle changes in Locale_id dropdown.
   * @param e
   */
  const handleLocaleIdChange = (e) => {
    setLocaleId(e.value);
  };
  /**
   * Function to handle changes in MTF Terminal Type dropdown.
   * @param type
   */
  const handleMtfTermTypeChange = (type) => {
    setMtfTermType(type);
    // Update display dimensions based on the selected type
    if (type.value === "vehicle") {
      setMtfDisplayWidth(40);
      setMtfDisplayHeight(8);
    } else {
      setMtfDisplayWidth(20); // Default values for handheld
      setMtfDisplayHeight(16);
    }
  };
  /**
   * generateQR.
   * @param rfProfile
   * @param seq_number
   */
  const generateQR = async (rfProfile, seq_number = null) => {
    setShowQRModal(true);
    setDialogueLoading(true);
    // Extracting sequence number from auto_devcod_seqnum
    const sequenceNumber =
      seq_number != null ? seq_number : parseInt(rfProfile.auto_devcod_seqnum);
    // Incrementing the sequence number by 1
    const nextSequenceNumber = sequenceNumber + 1;

    // Generating devcod and termID with the specified format
    const numberPart = nextSequenceNumber.toString().padStart(7, "0");
    const generatedDevcod = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
    const generatedTermId = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
    const data = {
      profileSetupGUID: rfProfile?.srfqrvm?.SRFProfilesSetupId,
      smartAppsURL: rfProfile?.srfqrvm?.SmartAppURL,
      mocaURL: rfProfile?.srfqrvm?.MOCAURL,
      mtfURL: rfProfile?.srfqrvm?.MTFURL,
      devcod: generatedDevcod,
      termId: generatedTermId,
      mtfTermType: mtfTermType?.value,
      mtfDisplayWidth: mtfDisplayWidth,
      mtfDisplayhight: mtfDisplayHeight,
      localeId: localeId?.value, // Set Locale_id
      mtfVendorName: `${rfProfile?.srfqrvm?.MTFVendorName}`, // Set Mtf_ven_nam
    };
    const jsonStr = JSON.stringify(data);
    const base64Str = btoa(jsonStr);
    setQRData(base64Str);
    setDevcod(generatedDevcod);
    setTermID(generatedTermId);
    setDialogueLoading(false);
  };
  /**
   * Handles editing an assistant.
   * @param rfProfile
   */
  const handleGenerateQRCode = (rfProfile) => {
    setSelectedProfile(rfProfile);
    setLocaleData([
      {
        value: rfProfile?.srfqrvm?.LocaleId,
        text: rfProfile?.srfqrvm?.LocaleId,
      },
    ]);
    setLocaleId({
      value: rfProfile?.srfqrvm?.LocaleId,
      text: rfProfile?.srfqrvm?.LocaleId,
    });
    generateQR(rfProfile);
  };
  /**
   * checkAndUpdateQR.
   * @param profileId
   */
  const checkAndUpdateQR = async (profileId) => {
    if (selectedProfile) {
      try {
        const response = await getDeviceRegistrationStatus({
          profileSetupId: profileId,
        });
        const currentSeqNumber = parseInt(response?.Payload);
        const seqNumberFromTermID = parseInt(extractNumberFromString(termID));
        if (
          seqNumberFromTermID < currentSeqNumber ||
          seqNumberFromTermID === currentSeqNumber
        ) {
          setDialogueLoading(true);
          GenerateNotification(
            buildNotification({
              title: "Successfully Registered",
              description: `SM${currentSeqNumber.toString().padStart(7, "0")} registered successfully!`,
              style: "success",
            }),
            NOTIFICATION_TYPES.APP,
            EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
          );
          // Add a 1-second delay
          await new Promise((resolve) => setTimeout(resolve, 1000));
          const nextSequenceNumber = currentSeqNumber + 1;
          const numberPart = nextSequenceNumber.toString().padStart(7, "0");
          const generatedDevcod = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
          const generatedTermId = `SM${numberPart}`; // Ensure 'SM' prefix followed by 7-digit number
          setDevcod(generatedDevcod);
          setTermID(generatedTermId);
          generateQR(selectedProfile, currentSeqNumber);
          setDialogueLoading(false);
        }
      } catch (error) {
        console.error("Error checking device registration status:", error);
      }
    }
  };

  React.useEffect(() => {
    if (selectedProfile) {
      // Call the function initially
      checkAndUpdateQR(selectedProfile?.srfqrvm?.SRFProfilesSetupId);

      // Set interval to repeatedly check and update QR every 5 seconds
      const intervalId = setInterval(() => {
        checkAndUpdateQR(selectedProfile?.srfqrvm?.SRFProfilesSetupId);
      }, 5000);

      // Clear interval when component unmounts to prevent memory leaks
      return () => clearInterval(intervalId);
    }
  }, [selectedProfile, termID]);

  React.useEffect(() => {
    if (selectedProfile) {
      generateQR(selectedProfile);
    }
  }, [mtfTermType, localeId]);

  /**
   * show edit content slider
   * @param {*} dataItem
   */
  const editContentSlider = (dataItem) => {
    setEditItem(dataItem);
  };
  const handleCopy = async (event) => {
    event.stopPropagation(); // Prevent the click from bubbling up to the parent
    try {
      await navigator.clipboard.writeText(qrData);
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto k-px-4">
        <React.Suspense fallback={<Loader />}>
          <ProfileSetupDataTable
            profile={profile}
            deleteFilter={deleteFilter}
            applyFilter={applyFilter}
            saveFilters={saveFilters}
            moduleName={RFPROFILESETUP}
            dataColumns={dataColumnsArray}
            gridColumnsList={() =>
              GridColumnsList({ editContentSlider, handleGenerateQRCode })
            }
            dataTableName={RFPROFILESETUP}
            setIsReady={setIsReady}
            isReady={isReady}
            rowLayoutConfigName={`${RFPROFILESETUP}.DataGrid.RowLayout`}
            pageLengthConfigName={`${RFPROFILESETUP}.DataGrid.ItemsPerPage`}
            dataSet={userData}
            setDataSet={setUserData}
            initialSort={{
              field: "id",
              dir: "desc",
            }}
            isCardonMobileView={false}
            total={process.env.DefaultLimit}
            defaultRowLayout={process.env.RowLayout}
            rowLayouts={ROW_LAYOUTS}
            pageSizes={PAGE_SIZE}
            editItem={editItem}
          />
        </React.Suspense>
        <ConfigurationContentSlider show={showQRModal}>
          <SliderCard
            title={"Generate QR"}
            handleSlide={() => {
              setShowQRModal(false);
              setSelectedProfile(null);
            }}
            className="ai-slider"
          >
            <SliderCard.Body>
              {dialogueLoading && <Loader />}
              {qrData && (
                <>
                  <div>
                    <div
                      className="flip-card-container"
                      onClick={() => setIsQrCode(!isQrCode)}
                    >
                      <div
                        className={`flip-card ${!isQrCode ? "flipped" : ""}`}
                      >
                        <div className="flip-card-front qrCode k-text-center">
                          <div
                            key="qr-code"
                            className="content-item qr-code-content k-bg-white"
                          >
                            <QRCode
                              value={qrData}
                              size={260}
                              viewBox={`0 0 260 260`}
                            />
                          </div>
                        </div>
                        <div className="flip-card-back baseString">
                          <div className="copy-button-container field-wrapper">
                            <span
                              onClick={handleCopy}
                              className="open-editor-icon"
                            >
                              <FontAwesomeIcon
                                className="icon-full-screen k-mr-1"
                                icon={faCopy}
                              />
                            </span>
                          </div>
                          <div
                            key="base64"
                            className="content-item base64-field k-bg-white"
                          >
                            <Label>Base 64:</Label>
                            <TextArea
                              value={qrData}
                              className="base64-textarea"
                              disabled
                              rows={6}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <hr />
                    <Label>Terminal Type</Label>
                    <DropDownList
                      data={[
                        { value: "handheld", text: "Handheld" },
                        { value: "vehicle", text: "Vehicle" },
                      ]}
                      value={mtfTermType}
                      textField="text"
                      dataItemKey="text"
                      className="!k-mb-3"
                      onChange={(e) => handleMtfTermTypeChange(e.target.value)}
                    />
                    <Label>Device Display Width:</Label>
                    <Input
                      value={mtfDisplayWidth}
                      readOnly
                      className="!k-mb-3"
                      disabled // Non-editable for now
                    />
                    <Label>Device Display Height:</Label>
                    <Input
                      value={mtfDisplayHeight}
                      readOnly
                      className="!k-mb-3"
                      disabled // Non-editable for now
                    />
                    {/* Dropdown for selecting Locale_id */}
                    <Label>Locale ID</Label>
                    <DropDownList
                      data={localeData} // Only one value for now
                      value={localeId}
                      textField="text"
                      dataItemKey="text"
                      onChange={handleLocaleIdChange}
                      className="!k-mb-3"
                    />
                    <Label>Devcod</Label>
                    <Input
                      value={devcod}
                      onChange={(e) => {
                        setDevcod(e.target.value);
                        handleInputChange(e, "devcod");
                      }}
                      required={true}
                      className="!k-mb-3"
                      readOnly
                    />
                    <Label>Term ID</Label>
                    <Input
                      value={termID}
                      onChange={(e) => {
                        setTermID(e.target.value);
                        handleInputChange(e, "termID");
                      }}
                      required={true}
                      className="!k-mb-3"
                      readOnly
                    />
                  </div>
                </>
              )}
            </SliderCard.Body>
          </SliderCard>
        </ConfigurationContentSlider>
      </div>
    </ErrorBoundary>
  );
};
export default RFProfileSetup;
